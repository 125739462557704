@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
    margin: 0;
    font-family: 'Lato', sans-serif, -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App {
    text-align: center;
    width: 100%;
}

.card-text {
    text-align: left;
}
html,
body {
    margin: 0%;
}
body {
    background: linear-gradient(
        to top left,
        rgb(22, 137, 148),
        rgb(62, 146, 204)
    );
    background-repeat: no-repeat;
}
html {
    --imp-text-color: #fffaff;
    --imp-nav-color: #fffaff;
    --imp-primary-color: #177e89;
    --imp-primary-color-rgb: rgb(23, 126, 137);
    --imp-secondary-color: #084c61;
    --imp-primary-color-rgb: rgb(8, 76, 97);
    --imp-primary-cerise: #ffc857;
    --imp-primary-cerise-rgb: rgb(216, 49, 91);
    --imp-primary-jet: #323031;
    --imp-primary-jet-rgb: rgb(50, 48, 49);
}

.purple {
    color: #fffaff !important;
    color: var(--imp-text-color) !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #83c5be;
    /* border-radius: 12px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #83c5be;
    border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
    background-color: #fffaff !important;
    background-color: var(--imp-nav-color) !important;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0px 10px 10px 0px rgba(0, 109, 119, 0.171) !important;
    -webkit-backdrop-filter: blur(15px) !important;
            backdrop-filter: blur(15px) !important;
}

.navbar {
    background-color: #fffaff !important;
    background-color: var(--imp-nav-color) !important;
    position: fixed !important;
    transition: all 0.3s ease-out 0s !important;
    padding: 0.3rem 2rem !important;
    font-size: 1.2rem !important;
}

.navbar-toggler {
    padding: 0.25rem 1.5rem !important;
    position: relative !important;
}

.navbar-toggler span {
    display: block !important;
    background-color: #323031 !important;
    background-color: var(--imp-primary-jet) !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    -webkit-transform: rotate(0deg) !important;
            transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    transition: -webkit-transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    -webkit-transform: rotate(135deg) !important;
            transform: rotate(135deg) !important;
    opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    -webkit-transform: rotate(-135deg) !important;
            transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
}

.navbar-brand {
    color: #323031 !important;
    color: var(--imp-primary-jet) !important;
}

.navbar-nav .nav-link {
    color: #323031 !important;
    color: var(--imp-primary-jet) !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.nav-link {
    padding: 0.8rem 1rem !important;
}

.navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
}

.navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
    border-radius: 10px;
}
.navbar-nav .nav-item a:hover {
    background-color: #fbfbfb;
    border-radius: 10px;
}

.navbar-nav .nav-item a::after {
    content: '';
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: #ffc857;
    background: var(--imp-primary-cerise);
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
    width: 100%;
    /* background-color: #006d77; */
}
#tsparticles {
    position: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
}

.home-header {
    padding-top: 80px !important;
}

.home-section {
    position: relative;
    color: #fffaff;
    color: var(--imp-text-color);
    /* z-index: 0; */

    height: 100%;
    width: 100%;
    margin: 0 auto;
    /* padding-top: 0px !important; */
}

.home-content {
    padding: 9rem 0 2rem !important;
    /* color: whitesmoke; */
    /* text-align: right; */
}

.heading {
    text-align: left;
    padding-top: 30px;
    font-size: 2.4em !important;
    /* padding-left: 120px !important; */
}

.heading-name {
    text-align: left;
    font-size: 2.5em !important;
    /* padding-left: 120px !important; */
}

.main-name {
    color: #fffaff;
    color: var(--imp-text-color);
}

.react-type-writer {
    /* text-align: left !important; */
    /* padding-left: 120px !important; */
    display: flex;
    font-size: 2.2em !important;
    color: #323031 !important;
    justify-content: left;
    font-weight: 600 !important;
}

.home-about-section {
    position: relative;
    /* padding-left: 50px !important; */
    text-align: left;
}

.home-about-description {
    color: #fffaff !important;
    color: var(--imp-text-color) !important;
    padding-top: 100px !important;
    padding-bottom: 50px !important;
    text-align: left;
    /* padding-left: 120px !important; */
}

.home-about-body {
    padding-top: 50px;
    font-size: 1.2em !important;
    text-align: left;
}

.home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: #fffaff !important;
    color: var(--imp-text-color) !important;
}

.home-about-social-links {
    justify-content: center !important;
    padding-top: 15px !important;
    display: inline-block !important;
    position: relative !important;
    -webkit-padding-start: 0 !important;
            padding-inline-start: 0 !important;
}

.social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
}

/* .icon-colour {
  color: #700c86 !important;
} */

/* --------- */
/* Footer */
/* --------- */
.footer {
    /* line */
    background-color: var(--imp-footer-color);
    /* background-color: var(--imp-secondary-color); */
    background-image: var(--image-gradient);
    /* background-image: var(--section-background-color) !important; */
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important ;
}
.footer-copyright {
    text-align: center !important;
}

.footer-body {
    text-align: center !important;
    z-index: 2;
}

@media (max-width: 600px) {
    .footer-copyright {
        text-align: center !important;
    }

    .footer-body {
        z-index: 2;
        text-align: center !important;
    }
}

.footer h3 {
    font-size: 1em;
    color: #fffaff !important;
    color: var(--imp-text-color) !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}
.footer-icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
}

.blockquote-footer {
    color: #a588c0 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
    z-index: 2;

    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important;
    /* background-image: var(--imp-primary-cerise) !important;  */
}
/* Hot fix for the responsive */
/* @media (max-width: 600px) {
    .project-section {
        z-index: 2;
        position: relative !important;
        padding-top: 150px !important;
        padding-bottom: 30px !important;
        padding-left: 150px !important;
        padding-right: 150px !important;
        background-image: var(--image-gradient);
    }
} */

.project-card {
    z-index: 10;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
}

.card {
    background-color: #084c61 !important;
    border-radius: 1em 0em 1em 0em !important;
    border: 0px !important;
}

.project-card-view {
    box-shadow: rgba(131, 197, 190, 0.459) 0px 50px 100px -20px,
        rgba(0, 109, 119, 0.3) 0px 30px 35px -30px,
        rgba(131, 197, 190, 0.1) 0px -2px 6px 0px inset;

    color: #fffaff !important;

    color: var(--imp-text-color) !important;
    /* background-color: transparent !important; */
    /* opacity: 0.9 !important; */
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
}
.project-card-view:hover {
    -webkit-transform: scale(1.02) !important;
            transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: rgba(8, 76, 97, 0.459) 0px 25px 50px -10px,
        rgba(77, 219, 235, 0.3) 0px 20px 25px -20px,
        rgba(219, 58, 52, 0.1) 0px -1px 3px 0px inset;
}
.card-img-top,
card-img {
    margin: 0px;
    padding: 0px;
    opacity: 0.8 !important;
    border-radius: 1em 0em 0em 0em !important;
}

.btn-primary {
    color: #fffaff !important;
    color: var(--imp-text-color) !important;
    background-color: #177e89 !important;
    background-color: var(--imp-primary-color) !important;
    border-color: #084c61 !important;
    border-color: var(--imp-secondary-color) !important;
    position: relative;
    margin: 10px;
}

.btn-primary:hover {
    color: #fffaff !important;
    color: var(--imp-text-color) !important;
    background-color: #006d77 !important;
    opacity: 0.94;
    border-color: #83c5be !important;
}
.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}
.project-heading {
    color: #084c61 !important;
    color: var(--imp-secondary-color) !important;
    font-size: 2.3em !important;
    font-weight: 500 !important;
    padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
}

.tech-icons {
    font-size: 4.5em !important;
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 5px !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
}

@media (max-width: 600px) {
    .tech-icons {
        margin: 10px !important;
    }
}

.tech-icons:hover {
    -webkit-transform: scale(1.05) !important;
            transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}
.tech-icon-images {
    padding: 20px !important;
    line-height: 1.6 !important;
}

.quote-card-view {
    border: none !important;
    color: white !important;
    background-color: transparent !important;
}

.about-activity {
    list-style: none !important;
    text-align: left !important;
    padding-left: 1px !important;
}

@media (max-width: 600px) {
    .about-img {
        padding-top: 0 !important;
    }
}
/* --------- */
/* Resume */
/* --------- */
.resume-section {
    position: relative !important;
    padding-top: 110px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
}

.resume {
    padding-top: 10px;
    padding-bottom: 50px;
}

.resume-left {
    padding-right: 80px !important;
}

.resume-right {
    padding-left: 80px !important;
}

@media (max-width: 600px) {
    .resume-left {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .resume-right {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}
.resume .resume-title {
    font-size: 2em;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 30px;
}

.resume .resume-item {
    padding: 0 0 10px 25px;
    margin-top: -2px;
    border-left: 2px solid #8a49a8;
    position: relative;
}

.resume .resume-item .resume-title {
    line-height: 18px;
    font-size: 0.9em;
    background: #5234795d;
    padding: 8px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
}

.resume .resume-item ul {
    padding-left: 20px;
    text-align: justify;
}

.resume .resume-item ul li {
    padding-bottom: 10px;
    list-style: none;
}

.resume .resume-item:last-child {
    padding-bottom: 0;
}

.resume .resume-item::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fffaff;
    background: var(--imp-text-color);
    border: 2px solid #8a49a8;
}

.animate-like {
    -webkit-animation-name: likeAnimation;
            animation-name: likeAnimation;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-duration: 0.85s;
            animation-duration: 0.85s;
}
@-webkit-keyframes likeAnimation {
    0% {
        -webkit-transform: scale(1.5);
                transform: scale(1.5);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@keyframes likeAnimation {
    0% {
        -webkit-transform: scale(1.5);
                transform: scale(1.5);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.git-btn {
    font-size: 1.1em !important;
}

.git-btn-inner {
    line-height: 1.4em !important;
    color: #323031 !important;
    color: var(--imp-primary-jet) !important;

    padding: 0.25rem 1.1rem !important;
    vertical-align: middle !important;
    text-align: center !important;
    background-color: #fffaff !important;
    background-color: var(--imp-nav-color) !important;
    border-color: #fffaff !important;
    border-color: var(--imp-nav-color) !important;
}

.git-btn-inner:hover {
    -webkit-transform: translateY(-2px) !important;
            transform: translateY(-2px) !important;
    background-color: #ffc857 !important;
    background-color: var(--imp-primary-cerise) !important;
    border-color: #fffaff !important;
    border-color: var(--imp-nav-color) !important;
}
.git-btn-inner::after {
    display: none !important;
}

.git-btn-color {
    color: #323031 !important;
    color: var(--imp-primary-jet) !important;
}

.project-link {
    background-color: #c59129 !important;
    display: inline !important;
    width: 100% !important;
    height: 100% !important;
}
.container-fluid {
    width: auto;
}

